// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-arak-jsx": () => import("./../../../src/pages/arak.jsx" /* webpackChunkName: "component---src-pages-arak-jsx" */),
  "component---src-pages-arakmobil-jsx": () => import("./../../../src/pages/arakmobil.jsx" /* webpackChunkName: "component---src-pages-arakmobil-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kapcsolat-jsx": () => import("./../../../src/pages/kapcsolat.jsx" /* webpackChunkName: "component---src-pages-kapcsolat-jsx" */),
  "component---src-pages-kapcsolatmobil-jsx": () => import("./../../../src/pages/kapcsolatmobil.jsx" /* webpackChunkName: "component---src-pages-kapcsolatmobil-jsx" */),
  "component---src-pages-mobil-jsx": () => import("./../../../src/pages/mobil.jsx" /* webpackChunkName: "component---src-pages-mobil-jsx" */),
  "component---src-pages-szolgaltatasok-jsx": () => import("./../../../src/pages/szolgaltatasok.jsx" /* webpackChunkName: "component---src-pages-szolgaltatasok-jsx" */),
  "component---src-pages-szolgaltatasokmobil-jsx": () => import("./../../../src/pages/szolgaltatasokmobil.jsx" /* webpackChunkName: "component---src-pages-szolgaltatasokmobil-jsx" */)
}

